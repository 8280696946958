import { ResponseWrapper } from 'common';

export const formsListenersInitialState: FormListenersState = {
    formListenersData: {
        loading: false,
        data: null
    },
    formListenerDetails: {
        loading: false,
        data: null
    },
    recentData: {
        loading: false,
        data: null
    },
    generatedTestLink: {
        loading: false,
        data: null
    },
    formListenerReport: {
        loading: false,
        data: null
    },
    ignoredFields: {
        loading: false,
        data: null
    },
};

export interface FormListenersState {
    formListenersData?: ResponseWrapper<FormListenersResponse>;
    formListenerDetails?: ResponseWrapper<FormListenerDetails>;
    constantValues?: ResponseWrapper<FormListenerConstantValue[]>;
    recentData?: ResponseWrapper<FormListenerRecentDataItem[]>;
    generatedTestLink?: ResponseWrapper<GeneratedTestLink>;
    formListenerReport?: ResponseWrapper<FormListenerReportItem[]>;
    ignoredFields?: ResponseWrapper<FormListenerIgnoredField[]>;
}
export interface FormListenersResponse {
    totalResultCount: number;
    items: Array<FormListener>;
}

export interface GeneratedTestLink {
    generatedTestLink: string,
    expireDate: string
}

export interface FormListener {
    formListenerId: number;
    name: string;
    formUrl: string;
    liveSubmissions: number;
    testSubmissions: number;
    status: string;
    createdBy: string;
    createdDate: string;
}

export interface FormListenerDetails {
    formListenerId: number;
    name: string;
    formUrl: string;
    description: string;
    attachId: string;
    submitButtonId: string;
    ftpUrl: string;
    formDataDelimiter: string;
    exactMatchUrl: boolean;
    trackingNumber: string;
    followUpEmail: string;
    followUpFirstName: string;
    followUpLastName: string;
    followUpSubject: string;
    statusCode: number;
    properties: string;
    typeCode: number;
    inputId: number;
    availableInputs: Array<FormListenerInput>;
    formFields: Array<string>;
    errors: Array<string>;
    createdBy: string;
}

export interface FormListenerRecentDataItem {
    name: string,
    value: string
}

export interface FormListenerInput {
    inputId: number;
    inputName: string;
}

export interface FormListenerEditSave {
    formListenerId?: number;
    name?: string;
    formUrl?: string;
    description?: string;
    formDataDelimiter?: string;
    ftpUrl?: string;
    statusCode?: number;
    exactMatchUrl?: boolean;
    attachId?: string;
    submitButtonId?: string;
    inputId?: number;
}

export enum FormListenerStatus {
    PENDING = 0,
    ACTIVE = 1,
    PAUSED = 8,
    DELETED = 3
}

export interface FormListenerConstantValue {
    applicationStringId: number;
    name: string;
    value: string;
    createdBy: string;
}

export interface FormListenerIgnoredField {
    code: number,
    name: string
}

export interface FormListenerReportItem {
    chartTitle: string;
    tableTitle: string;
    chartId: string;
    headers: Array<string>;
    dataRows: Array<Array<string>>;
    type: string;
    showXAxisLabels: boolean;
    showYAxisLabels: boolean;
    getxAxisTitle: string;
    getyAxisTitle: string;
    yAxisTitle: string;
    displayDataLimit: number;
    getxRotation: number;
    getyRotation: number;
    tableColumnCount: number;
    dataForJavascript: string;
    xaxisNames: string;
    yRotation: number;
    xRotation: number;
    xAxisTitle: string;
}

export interface FormListenerSetupFollowUpEmail {
    formListenerId: number;
    trackingNumber: string;
    followUpEmail: string;
    followUpFirstName: string;
    followUpLastName: string;
    followUpSubject: string;
}
