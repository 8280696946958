import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { FormListenerConstantValue, FormListenerDetails, FormListenerEditSave, FormListenerIgnoredField, FormListenerRecentDataItem, FormListenerReportItem, FormListenerSetupFollowUpEmail, FormListenersResponse, GeneratedTestLink } from './form-listeners.interface';

export enum CustomerDataPlatformFormListenersHttpActionTypes {
    LoadFormListeners = '[CdpFormListeners] Load Form Listeners',
    LoadFormListenerDetails = '[CdpFormListeners] Load Form Listener Details',
    SaveFormListener = '[CdpFormListeners] Save Form Listener',
    UpdateFormListenerStatus = '[CdpFormListeners] Update Form Listener Status',
    GetConstantValues = '[CdpFormListeners] Get Constant Values',
    UpdateConstantValue = '[CdpFormListeners] Update Constant Value',
    LoadReportData = '[CdpFormListeners] Load Report Data',
    DeleteConstantValue = '[CdpFormListeners] Delete Constant Value',
    OutputTestSubmissions = '[CdpFormListeners] Output Test Submissions',
    LoadRecentData = '[CdpFormListeners] Load Recent Data',
    IgnoreFormField = '[CdpFormListeners] Ignore Form Field',
    GenerateTestLink = '[CdpFormListeners] Generate Test Link',
    GetIgnoredFields = '[CdpFormListeners] Get Ignored Fields',
    IgnoreField = '[CdpFormListeners] Ignore Field',
    StopIgnoringField = '[CdpFormListeners] Stop Ignoring Field',
    SetupFollowUpEmail = '[CdpFormListeners] Setup Follow Up Email',
}

export enum CustomerDataPlatformFormListenersInnerActionTypes {
    LoadFormListenersSuccess = '[CdpFormListeners] Load Form Listeners Success',
    LoadFormListenersError = '[CdpFormListeners] Load Form Listeners Error',
    LoadFormListenerDetailsSuccess = '[CdpFormListeners] Load Form Listener Details Success',
    LoadFormListenerDetailsError = '[CdpFormListeners] Load Form Listener Details Error',
    SaveFormListenerSuccess = '[CdpFormListeners] Save Form Listener Success',
    SaveFormListenerError = '[CdpFormListeners] Save Form Listener Error',
    UpdateFormListenerStatusSuccess = '[CdpFormListeners] Update Form Listener Status Success',
    UpdateFormListenerStatusError = '[CdpFormListeners] Update Form Listener Status Error',
    GetConstantValuesSuccess = '[CdpFormListeners] Get Constant Values Success',
    GetConstantValuesError = '[CdpFormListeners] Get Constant Values Error',
    LoadReportDataSuccess = '[CdpFormListeners] Load Report Data Success',
    LoadReportDataError = '[CdpFormListeners] Load Report Data Error',
    UpdateConstantValueSuccess = '[CdpFormListeners] Update Constant Value Success',
    UpdateConstantValueError = '[CdpFormListeners] Update Constant Value Error',
    CreateConstantValueSuccess = '[CdpFormListeners] Create Constant Value Success',
    DeleteConstantValueSuccess = '[CdpFormListeners] Delete Constant Value Success',
    DeleteConstantValueError = '[CdpFormListeners] Delete Constant Value Error',
    OutputTestSubmissionsSuccess = '[CdpFormListeners] Output Test Submissions Success',
    OutputTestSubmissionsError = '[CdpFormListeners] Output Test Submissions Error',
    LoadRecentDataSuccess = '[CdpFormListeners] Load Recent Data Success',
    LoadRecentDataError = '[CdpFormListeners] Load Recent Data Error',
    IgnoreFormFieldSuccess = '[CdpFormListeners] Ignore Form Field Success',
    IgnoreFormFieldError = '[CdpFormListeners] Ignore Form Field Error',
    GenerateTestLinkSuccess = '[CdpFormListeners] Generate Test Link Success',
    GenerateTestLinkError = '[CdpFormListeners] Generate Test Link Error',
    ClearGeneratedLinkState = '[CdpFormListeners] Clear Generated Link State',
    GetIgnoredFieldsSuccess = '[CdpFormListeners] Get Ignored Fields Success',
    GetIgnoredFieldsError = '[CdpFormListeners] Get Ignored Fields Error',
    IgnoreFieldSuccess = '[CdpFormListeners] Ignore Field Success',
    IgnoreFieldError = '[CdpFormListeners] Ignore Field Error',
    StopIgnoringFieldSuccess = '[CdpFormListeners] Stop Ignoring Field Success',
    StopIgnoringFieldError = '[CdpFormListeners] Stop Ignoring Field Error',
    SetupFollowUpEmailSuccess = '[CdpFormListeners] Setup Follow Up Email Success',
    SetupFollowUpEmailError = '[CdpFormListeners] Setup Follow Up Email Error',
}

// ===== HTTP =====
export const LoadFormListeners = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.LoadFormListeners,
    props<{ payload: { environmentId?: number, offset?: number, numResults?: number, sortBy?: string, order?: string, name?: string, createdDate?: string, createdBy?: string } }>()
);

export const LoadFormListenerDetails = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.LoadFormListenerDetails,
    props<{ payload: { formListenerId: number, environmentId?: string } }>()
);

export const SaveFormListener = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.SaveFormListener,
    props<{ payload: FormListenerEditSave }>()
);

export const UpdateFormListenerStatus = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.UpdateFormListenerStatus,
    props<{ payload: { formListenerId: number, action: string } }>()
);

export const GetConstantValues = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.GetConstantValues,
    props<{ payload: { formListenerId: number } }>()
);

export const LoadReportData = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.LoadReportData,
    props<{ payload: { formListenerId: number, startDate: string, endDate: string } }>()
);

export const UpdateConstantValue = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.UpdateConstantValue,
    props<{ payload: { constantValue: FormListenerConstantValue, formListenerId: string } }>()
);

export const DeleteConstantValue = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.DeleteConstantValue,
    props<{ payload: { applicationStringId: number, formListenerId: number } }>()
);

export const OutputTestSubmissions = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.OutputTestSubmissions,
    props<{ payload: { formListenerId: number } }>()
);

export const LoadRecentData = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.LoadRecentData,
    props<{ payload: { formListenerId: number } }>()
);

export const IgnoreFormField = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.IgnoreFormField,
    props<{ payload: { formListenerId: number, formFieldName: string } }>()
);

export const GenerateTestLink = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.GenerateTestLink,
    props<{ payload: { formListenerId: number } }>()
);

export const GetIgnoredFields = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.GetIgnoredFields,
    props<{ payload: { formListenerId: number } }>()
);

export const IgnoreField = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.IgnoreField,
    props<{ payload: { formListenerId: number, formFieldName: string } }>()
);

export const StopIgnoringField = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.StopIgnoringField,
    props<{ payload: { formListenerId?: number, applicationStringId: string } }>()
);

export const SetupFollowUpEmail = createAction(
    CustomerDataPlatformFormListenersHttpActionTypes.SetupFollowUpEmail,
    props<{ payload: FormListenerSetupFollowUpEmail }>()
);

// ====== INNER ======
export const LoadFormListenersSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.LoadFormListenersSuccess,
    props<{ payload: FormListenersResponse }>()
);

export const LoadFormListenersError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.LoadFormListenersError,
    props<{ payload: BasicResponse }>()
);

export const LoadFormListenerDetailsSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.LoadFormListenerDetailsSuccess,
    props<{ payload: ResponseWrapper<FormListenerDetails> }>()
);

export const LoadFormListenerDetailsError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.LoadFormListenerDetailsError,
    props<{ payload: BasicResponse }>()
);

export const SaveFormListenerSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.SaveFormListenerSuccess,
    props<{ payload: FormListenerDetails }>()
);

export const SaveFormListenerError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.SaveFormListenerError,
    props<{ payload: BasicResponse }>()
);

export const UpdateFormListenerStatusSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.UpdateFormListenerStatusSuccess,
    props<{ payload: { formListenerId: number, action: string } }>()
);

export const UpdateFormListenerStatusError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.UpdateFormListenerStatusError,
    props<{ payload: BasicResponse }>()
);

export const GetConstantValuesSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.GetConstantValuesSuccess,
    props<{ payload: FormListenerConstantValue[] }>()
);

export const GetConstantValuesError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.GetConstantValuesError,
    props<{ payload: BasicResponse }>()
);

export const LoadReportDataSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.LoadReportDataSuccess,
    props<{ payload: FormListenerReportItem[] }>()
);

export const LoadReportDataError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.LoadReportDataError,
    props<{ payload: BasicResponse }>()
);

export const UpdateConstantValueSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.UpdateConstantValueSuccess,
    props<{ payload: FormListenerConstantValue }>()
);

export const UpdateConstantValueError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.UpdateConstantValueError,
    props<{ payload: BasicResponse }>()
);

export const CreateConstantValueSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.CreateConstantValueSuccess,
    props<{ payload: BasicResponse }>()
);

export const DeleteConstantValueSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.DeleteConstantValueSuccess,
    props<{ payload: { applicationStringId: number } }>()
);

export const OutputTestSubmissionsSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.OutputTestSubmissionsSuccess
);

export const OutputTestSubmissionsError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.OutputTestSubmissionsError,
    props<{ payload: BasicResponse }>()
);

export const LoadRecentDataSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.LoadRecentDataSuccess,
    props<{ payload: FormListenerRecentDataItem[] }>()
);

export const LoadRecentDataError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.LoadRecentDataError,
    props<{ payload: BasicResponse }>()
);

export const IgnoreFormFieldSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.IgnoreFormFieldSuccess
);

export const IgnoreFormFieldError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.IgnoreFormFieldError,
    props<{ payload: BasicResponse }>()
);

export const GenerateTestLinkSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.GenerateTestLinkSuccess,
    props<{ payload: GeneratedTestLink }>()
);

export const GenerateTestLinkError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.GenerateTestLinkError,
    props<{ payload: BasicResponse }>()
);

export const ClearGeneratedLinkState = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.ClearGeneratedLinkState
);

export const GetIgnoredFieldsSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.GetIgnoredFieldsSuccess,
    props<{ payload: FormListenerIgnoredField[] }>()
);

export const GetIgnoredFieldsError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.GetIgnoredFieldsError
);

export const IgnoreFieldSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.IgnoreFieldSuccess,
    props<{ payload: { formListenerId: number, formFieldName: string } }>()
);

export const IgnoreFieldError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.IgnoreFieldError
);

export const StopIgnoringFieldSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.StopIgnoringFieldSuccess
);

export const StopIgnoringFieldError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.StopIgnoringFieldError
);

export const SetupFollowUpEmailSuccess = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.SetupFollowUpEmailSuccess
);

export const SetupFollowUpEmailError = createAction(
    CustomerDataPlatformFormListenersInnerActionTypes.SetupFollowUpEmailError
);
